import React from 'react';

import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const Notifications = loadable(() => import('@screens/escala/Notifications'));

function Index({ id, ...props }) {
  const result = id?.split('-') || [];
  return (
    <Layout {...props}>
      <Notifications {...props} id={result[0]} gestorId={result[1]} />
    </Layout>
  );
}

export default Index;
